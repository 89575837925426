import React from 'react'

export interface LogoProps {
	src: string
	alt: string
	maxWidth: string
	maxHeight: string
}

interface LogoListProps {
	title: string
	logos: LogoProps[]
	numColumns?: number
}

const LogoList: React.FC<LogoListProps> = ({
	title,
	logos,
	numColumns = 4,
}: LogoListProps) => {
	return (
		<div className="container mx-auto py-16 z-10">
			<h2 className="font-sans text-gray-500 text-lg tracking-wide uppercase text-center mb-8">
				{title}
			</h2>
			<ul className="flex flex-wrap items-center justify-start md:justify-evenly">
				{logos.map((logo, i) => (
					<li className={`w-1/2 sm:w-1/3 md:w-1/${numColumns} p-4`} key={i}>
						<img
							className="w-auto h-auto mx-auto"
							style={{ maxWidth: logo.maxWidth, maxHeight: logo.maxHeight }}
							src={logo.src}
							alt={logo.alt}
						/>
					</li>
				))}
			</ul>
		</div>
	)
}

export default LogoList
